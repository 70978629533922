import { useState } from "react";

import { Stack } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import { User, UserType } from "../../types";
import { useAdminUpdateUser } from "../../store";
import TelehealthIconButton from "../telehealth-icon-button";
import UpdateTypeSelect from "../update-type-select";
import toast from "react-hot-toast";

interface UpdateUserTypeProps {
    user: User;
}

const UpdateUserType: React.FC<UpdateUserTypeProps> = ({
    user,
}) => {

    const [userType, setUserType] = useState<UserType>(user.userType);

    const onUpdateSuccess = () => {
        toast.success("User type updated successfully");
    }

    const onUpdateFailure = (err: any) => {
        console.log(err);
        toast.error("Failed to update user type");
    }

    const { mutate: adminUpdateUser } = useAdminUpdateUser(onUpdateSuccess, onUpdateFailure);

    const options: string[] = Object.values(UserType).filter((type) => {
        if (type === UserType.Inactive) {
            return false;
        }

        return type !== UserType.Temporary;
    });

    const submitForm = () => {
        adminUpdateUser({ ...user, userType: userType });
    }

    return (
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <UpdateTypeSelect
                value={userType}
                onChange={(event) => setUserType(event.target.value as UserType)}
                options={options}
                name={"userType"}
                label={"User Type"}
                maxWidth={250}
                minWidth={250}
            />
            <TelehealthIconButton
                onClick={submitForm}
                icon={<SaveIcon />}
                color="primary"
                tooltip={"Click to save changes"}
                inactiveTooltip={"Type has not changed"}
                disabled={userType == user.userType} />
        </Stack>
    );
}

export default UpdateUserType;