import { useContentShareControls, useContentShareState } from 'amazon-chime-sdk-component-library-react';
import React from 'react';

import ScreenShareIcon from '@mui/icons-material/ScreenShare';

import ControlButton from '../control-button';

interface LabelledContentShareButton {
    showText?: boolean;
    disabled?: boolean;
}

export const LabelledContentShareButton: React.FC<React.PropsWithChildren<LabelledContentShareButton>> = ({
    showText,
    disabled = false
}) => {
    const { isLocalUserSharing } = useContentShareState();
    const { toggleContentShare } = useContentShareControls();

    const changeContentShare = () => {
        toggleContentShare();
    }


    return (
        <ControlButton
            disabled={disabled}
            activeIcon={<ScreenShareIcon color='action' />}
            inactiveIcon={<ScreenShareIcon color='action' />}
            active={isLocalUserSharing}
            onClick={changeContentShare}
            activeLabel={'Stop Content Share'}
            inactiveLabel={'Start Content Share'}
            showText={showText}
        />
    );
};

export default LabelledContentShareButton;