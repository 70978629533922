import { Stack } from "@mui/material";

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import TelehealthStyledButton from "./telehealth-styled-button";

interface DownloadBarProps {
    acceptLabel?: string;
    tooltip?: string;
    disabled?: boolean;
    currentOptionIndex: number;
    optionsLength: number;
    showText?: boolean;
    onAccept?: () => void;
    onNavigate: (index: number) => void;
}

const DownloadBar: React.FC<DownloadBarProps> = ({
    acceptLabel = "Download",
    tooltip = "Download",
    disabled,
    showText,
    onAccept,
    onNavigate,
    currentOptionIndex,
    optionsLength,
}: DownloadBarProps) => {

    const navigationDisabled = disabled || optionsLength < 2;
    const acceptDisabled = disabled || !onAccept || optionsLength < 1;

    const onNavigateNext = () => {
        if (optionsLength < 2) {
            return;
        }

        if (currentOptionIndex + 1 < optionsLength) {
            onNavigate(currentOptionIndex + 1);
        } else {
            onNavigate(0);
        }
    }

    const onNavigatePrevious = () => {
        if (optionsLength < 2) {
            return;
        }

        if (currentOptionIndex - 1 >= 0) {
            onNavigate(currentOptionIndex - 1);
        } else {
            onNavigate(optionsLength - 1);
        }
    }

    return (
        <Stack direction="row"
            spacing={2}
            width={"100%"}
            justifyContent={"center"}>
            <TelehealthStyledButton
                icon={<ArrowBackIcon />}
                disabled={navigationDisabled}
                tooltip="Navigate Previous"
                label="Previous"
                showText={showText}
                onClick={onNavigatePrevious}
                sx={{
                    color: "primary"
                }}
            />
            {onAccept &&
                <TelehealthStyledButton
                    icon={<DownloadForOfflineIcon />}
                    tooltip={tooltip}
                    label={acceptLabel}
                    disabled={acceptDisabled}
                    showText
                    onClick={onAccept}
                    sx={{
                        color: "primary"
                    }} />}
            <TelehealthStyledButton
                icon={<ArrowForwardIcon />}
                tooltip="Navigate Next"
                label="Next"
                disabled={navigationDisabled}
                showText={showText}
                onClick={onNavigateNext}
                sx={{
                    color: "primary"
                }} />
        </Stack>
    );
};

export default DownloadBar;