import React from "react";

import { BufferGeometry, Euler, MeshStandardMaterial, NormalBufferAttributes, Vector3 } from "three";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { useLoader } from "@react-three/fiber";
import { Html } from "@react-three/drei";

import ExpirationCard from "./expiration-card";

interface ModelFromUrlProps {
    url: string;
    position?: Vector3;
    rotation?: Euler;
    expired: boolean;
    onClick: (event: any) => void;
}

const ModelFromUrl: React.FC<ModelFromUrlProps> = ({
    url,
    position,
    rotation,
    expired,
    onClick
}: ModelFromUrlProps) => {

    const geometry: BufferGeometry<NormalBufferAttributes> | undefined
        = expired ?
            new BufferGeometry() :
            useLoader(PLYLoader, url);

    const material = new MeshStandardMaterial({
        vertexColors: geometry?.attributes.color !== undefined,
        metalness: 0.5,
        roughness: 0.5,
    });

    const [currentTime, setCurrentTime] = React.useState<number>(0);

    const onPointerDown = (event: any) => {
        const currentTime = new Date().getTime();

        setCurrentTime(currentTime);
    }

    const onPointerUp = (event: any) => {

        const pointUpTime = new Date().getTime();

        const timeDiff = pointUpTime - currentTime;

        console.log("Time diff: ", timeDiff);

        // don't call on click if a drag event
        if (timeDiff < 150) {
            onClick(event);
        }
    }

    return (
        <>
            <Html position={[0, 0, 0]}>
                {expired &&
                    <ExpirationCard message="Model has expired" />}
            </Html>
            <mesh
                position={position}
                rotation={rotation}
                geometry={geometry}
                scale={100}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}>
                <primitive object={material} attach="material" />
            </mesh>
        </>
    );
};

export default ModelFromUrl;