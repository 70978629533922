import React, { useContext, useEffect, useRef } from "react";

import { Stack } from "@mui/material";

import { useAudioVideo, useContentShareState, useLocalVideo, useRemoteVideoTileState, useRosterState } from "amazon-chime-sdk-component-library-react";

import VideoLabel from "./video-label";
import StyledIconShowSelfButton from "./styled-icon-show-self-button";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import { getMeetingTypeFromExternalId } from "../../services/chime-service";

interface SelectedAttendeeVideoProps {
    selectedAttendee: string;
    name?: string;
}

const SelectedAttendeeVideo: React.FC<SelectedAttendeeVideoProps> = ({
    selectedAttendee,
    name
}) => {

    const [attendeeId, setAttendeeId] = React.useState<number>(-1);

    const { isVideoEnabled } = useLocalVideo();
    const { attendeeIdToTileId } = useRemoteVideoTileState();

    const { isLocalUserSharing } = useContentShareState();
    const { showSelf } = useContext(MeetingInfoContext);

    const show = showSelf && !isLocalUserSharing;

    const { roster } = useRosterState();

    let toShowName = ""

    if (name) {
        // already has id split so we add a fake id to use function
        const result = getMeetingTypeFromExternalId(`id#${name}`);
        toShowName = result.userType.length > 0 ? `${result.name} ${result.userType}` : `${result.name}`
    }

    useEffect(() => {
        for (const attendee in roster) {

            const currentName = roster[attendee]?.externalUserId;

            if (!currentName) {
                continue;
            }

            if (currentName === selectedAttendee) {
                setAttendeeId(attendeeIdToTileId[attendee]);
                return;
            }
        }

        setAttendeeId(-1);

    }, [roster, attendeeIdToTileId, selectedAttendee]);

    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (!audioVideo || attendeeId < 0 || !videoEl.current) {
            return;
        }

        audioVideo.bindVideoElement(attendeeId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(attendeeId);
            if (tile) {
                audioVideo.unbindVideoElement(attendeeId);
            }
        };
    }, [audioVideo, attendeeId]);

    return (
        <div
            style={{
                position: "relative",
                width: "fit-content",
                height: "fit-content",
            }}>
            {attendeeId > -1 ?
                <>
                    < video
                        ref={videoEl}
                        style={{
                            position: "absolute",
                            display: show ? "flex" : "none",
                            bottom: "1rem",
                            left: "1rem",
                            width: "20vw",
                            maxHeight: "30vh",
                            height: "auto",
                            zIndex: 100,
                        }}
                    />
                    {name && show &&
                        <Stack width={"20vw"} >
                            <VideoLabel
                                name={toShowName}
                                distanceFromSide="1.25rem"
                                right={false}
                                zIndex={200} />
                        </Stack>}
                    {!isVideoEnabled && <div style={{
                        position: "absolute",
                        bottom: show ? "10rem" : "1rem",
                        right: show ? "4rem" : "-1rem",
                        zIndex: 100,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <StyledIconShowSelfButton right={false} zIndex={200} />
                    </div>
                    }
                </>
                : null}
        </div >
    );
}

export default SelectedAttendeeVideo;