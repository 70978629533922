import { ResourceType, User } from "../types";
import { telehealthStaticCredentialProvider } from "./telehealth-static-credential-provider";
import { mqtt, iot, mqtt5 } from 'aws-iot-device-sdk-v2';

import { toUtf8 } from "@aws-sdk/util-utf8-browser";
import { handleIotMessage } from "./iot-event-handler";
import { QueryClient } from "@tanstack/react-query";

const region = import.meta.env.VITE_AWS_REGION as string;
const websocketEndPoint = import.meta.env.VITE_WEBSOCKET_API_ENDPOINT as string

const channel = 'update/';

const subPacket: mqtt5.SubscribePacket =
{
    subscriptions: [
        {
            topicFilter: channel + ResourceType.Meetings,
            qos: mqtt.QoS.AtMostOnce,
        },
        {
            topicFilter: channel + ResourceType.Chime,
            qos: mqtt.QoS.AtMostOnce,
        },
        {
            topicFilter: channel + ResourceType.Uploads,
            qos: mqtt.QoS.AtMostOnce,
        },
        {
            topicFilter: channel + ResourceType.Patients,
            qos: mqtt.QoS.AtMostOnce,
        },
        {
            topicFilter: channel + ResourceType.Organisation,
            qos: mqtt.QoS.AtMostOnce,
        },
        {
            topicFilter: channel + ResourceType.Users,
            qos: mqtt.QoS.AtMostOnce,
        }
    ]
}

const unSubPacket: mqtt5.UnsubscribePacket =
{
    topicFilters: [channel + ResourceType.Meetings, channel + ResourceType.Chime, channel + ResourceType.Uploads, channel + ResourceType.Patients, channel + ResourceType.Organisation, channel + ResourceType.Users]
}

export const closeConnection = (clientConnection: mqtt5.Mqtt5Client | null, message?: string) => {
    if (!clientConnection) {
        return;
    }

    clientConnection.unsubscribe(unSubPacket);
    clientConnection.close();

    if (message) {
        console.log(message);
    }
}

export const waitToConnect = async (accessKeyId: string, secretKey: string, sessionToken: string, currentUser: User, queryClient: QueryClient) => {

    if (!accessKeyId || !secretKey || !sessionToken) {
        console.error('No access token or id token');
        return null;
    }

    const credentials = new telehealthStaticCredentialProvider(accessKeyId, secretKey, sessionToken, region);

    const config = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
        websocketEndPoint,
        {
            region: region,
            credentialsProvider: credentials,
        }).build();

    const client = new mqtt5.Mqtt5Client(config);

    client.on('connectionSuccess', () => {
        console.log('Connected to AWS IoT');
    });

    client.on("messageReceived", (eventData: mqtt5.MessageReceivedEvent): void => {

        if (!eventData.message.payload) {
            console.warn('No payload', eventData);
            return;
        }

        const payload = toUtf8(eventData.message.payload as Buffer);

        handleIotMessage(eventData.message.topicName, payload, currentUser.userId, queryClient);
    });

    client.start();

    client.subscribe(subPacket);

    return client;
}