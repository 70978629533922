import { Paper, Stack } from "@mui/material";

import { useContext } from "react";

import { MeetingInfoContext } from "../../context/meeting-info-context";

import LabelledMuteButton from "./labelled-mute-button";
import LabelledAudioButton from "./labelled-audio-button";
import LabelledCameraButton from "./labelled-camera-button";
import UploadImageButton from "../upload/upload-image-button";

interface MobileControlBarProps {
    showText?: boolean;
}

const MobileControlBar: React.FC<MobileControlBarProps> = ({
    showText,
}) => {

    const { connected } = useContext(MeetingInfoContext)

    return (
        <Paper
            elevation={20}
            sx={{
                mt: 4,
                padding: 1,
                borderRadius: "10px",
                height: "fit-content",
                width: "fit-content",
                position: "fixed",
                bottom: "10px",
                left: "50%",
                backgroundColor: "darkgray",
                transform: "translateX(-50%)",
                zIndex: 100
            }}
        >
            <Stack direction={"column"}
                spacing={1}
                alignContent={"center"}
                justifyContent={"center"}>
                <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="space-around">
                    <LabelledMuteButton startMuted={true} showText={showText} />
                    <LabelledAudioButton startWithAudio={false} showText={showText} />
                    <LabelledCameraButton startWithVideo={true} showText={showText} />
                </Stack>
                {connected &&
                    <Stack
                        spacing={1}
                        direction={"row"}>
                        <UploadImageButton
                            restartVideo={true}
                            capture={true}
                            showText={true} />
                    </Stack>}
            </Stack>
        </Paper >

    );
}

export default MobileControlBar;