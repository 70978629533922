import { useNavigate } from "react-router-dom";
import TelehealthMenuItem from "./telehealth-menu-item";
import { RouteType } from "../router";

interface NavigationMenuItemProps {
    navigationPath: RouteType;
    label: string;
    icon: React.ReactNode;
    disabled?: boolean;
}

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
    navigationPath,
    label,
    icon,
    disabled
}) => {

    const navigate = useNavigate();

    const navigateToPath = () => {
        navigate(navigationPath)
    }

    return (
        <TelehealthMenuItem
            disabled={disabled}
            label={label}
            icon={icon}
            onClick={navigateToPath}
        />
    );
}

export default NavigationMenuItem;