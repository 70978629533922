import CallEndIcon from '@mui/icons-material/CallEnd';

import ControlButton from "../control-button";
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingJoinType } from '../../types';

const baseURL = import.meta.env.VITE_WEBSITE_URL as string;

interface LeaveCallButtonProps {
    meetingType: MeetingJoinType;
    returnToHome?: boolean;
    showText?: boolean;
    meetingId?: string;
}

const LeaveCallButton = ({
    showText,
    returnToHome,
    meetingType,
    meetingId
}: LeaveCallButtonProps) => {

    const meetingManager = useMeetingManager();

    const createUrl = () => {

        const url = new URL(`${baseURL}/callended`);

        url.searchParams.append("meetingType", `${meetingType}`);

        if (meetingId && meetingId.length > 0) {
            url.searchParams.append("meetingId", meetingId);
        }

        return url.toString();
    }

    const url = createUrl();
    console.log(url);
    const homeUrl = "/dashboard";

    const LeaveCall = () => {
        meetingManager.leave();
        window.location.href = returnToHome ? homeUrl : url
    }

    return (
        <ControlButton
            active={true}
            activeIcon={<CallEndIcon color="action" />}
            activeLabel={"Leave Call"}
            inactiveLabel={"Leave Call"}
            activeColor="#c62828"
            showText={showText}
            onClick={LeaveCall}
        />
    )
}

export default LeaveCallButton;