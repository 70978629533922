import { parseISO } from "date-fns";

const getExpirationDate = (urlString: string) => {
    let url = null;

    try {
        url = new URL(urlString);
    } catch (e) {
        return null;
    }

    if (!url) {
        return null;
    }

    const xAmzDate = url.searchParams.get('X-Amz-Date');
    const xAmzExpires = url.searchParams.get('X-Amz-Expires');

    if (!xAmzDate || !xAmzExpires) {
        return null;
    }

    const creationDate = parseISO(xAmzDate);

    const expiresInSecs = Number(xAmzExpires);

    creationDate.setSeconds(creationDate.getSeconds() + expiresInSecs);

    return creationDate;
}

export const urlExpired = (urlString: string) => {

    if (urlString.length === 0) {
        return false;
    }

    const expirationDate = getExpirationDate(urlString);

    if (!expirationDate) {
        return false;
    }

    return expirationDate < new Date() || false;
}