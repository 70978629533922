import { Vector3 } from "three";
import PointsLine from "./points-line";


interface PointLineGroupProps {
    points: Vector3[];
    visible: boolean;
    color?: string;
    lineColor?: number;
}

const PointLineGroup: React.FC<PointLineGroupProps> = ({
    points,
    visible,
    color,
    lineColor,
}) => {

    const createLines = () => {
        return (
            points.map((point, index) => {
                return (
                    <PointsLine
                        key={index}
                        start={point}
                        end={index === points.length - 1 ? undefined : points[index + 1]}
                        visible={visible}
                        lineColor={lineColor}
                        color={color}
                    />
                )
            }))
    }

    return (
        <>
            {createLines()}
            {points.length > 1 && (
                <PointsLine
                    start={points[0]}
                    end={points[points.length - 1]}
                    visible={visible}
                    lineColor={lineColor}
                    color={color}
                />
            )}
        </>
    );
};

export default PointLineGroup;