import { QueryClient } from '@tanstack/react-query';

import { ResourceType, IotMessage, MessageEventType } from '../types';


const ignoreSelfTypes = [ResourceType.Meetings, ResourceType.Users, ResourceType.Organisation, ResourceType.Patients];

const handleUpdate = (iotMessage: IotMessage, queryClient: QueryClient) => {

  const cacheKey = iotMessage.resourceType;

  switch (iotMessage.eventType) {
    case MessageEventType.Created:
      console.log(`${cacheKey} Meeting`, iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [cacheKey],
        refetchType: "all",
      });
      break;
    case MessageEventType.Updated:
      console.log(`${cacheKey} Meeting'`, iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [cacheKey, iotMessage.resourceId],
        refetchType: "all",
      });
      break;
    case MessageEventType.Deleted:
      console.log(`${cacheKey} Meeting`, iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [cacheKey],
        refetchType: "all",
      });
      break;
    default:
      console.warn(`Not handled ${cacheKey} update`, iotMessage);
  }
}

const handleChimeUpdate = (iotMessage: IotMessage, queryClient: QueryClient) => {

  switch (iotMessage.eventType) {
    case MessageEventType.Custom:
      console.log('Chime Event', iotMessage.eventType);
      queryClient.invalidateQueries({
        queryKey: [ResourceType.Chime, iotMessage.resourceId],
        refetchType: "all",
      });
      break;
    default:
      console.warn('Not handled chime update', iotMessage);

  }
}

const handleUploadUpdate = (iotMessage: IotMessage, queryClient: QueryClient) => {

  if (iotMessage.eventType === MessageEventType.Created) {
    console.log('Added Upload', iotMessage.eventType);
    queryClient.invalidateQueries({
      queryKey: [ResourceType.Uploads, iotMessage.resourceId],
      refetchType: "all",
    });
  } else {
    console.warn('Not handled upload update', iotMessage);
  }
}

const handleUserUpdate = (iotMessage: IotMessage, queryClient: QueryClient) => {

  if (iotMessage.customInfo !== "organisation-updated") {
    console.warn("Not handled custom user update", iotMessage);
  }

  console.log('Organisation Updated', iotMessage.eventType);
  queryClient.invalidateQueries({
    queryKey: [ResourceType.Users, iotMessage.resourceId],
    refetchType: "all",
  });

  queryClient.invalidateQueries({
    queryKey: [ResourceType.Organisation],
    refetchType: "all",
  });
}

const handleOtherUpdate = (iotMessage: IotMessage) => {
  console.warn('Not handled update', iotMessage);
}

export const handleIotMessage = (topicName: string, payload: string, userId: string, queryClient: QueryClient): void => {
  const iotMessage: IotMessage = JSON.parse(payload);

  const splitTopic = topicName.split('/');

  if (splitTopic.length == 0) {
    console.warn('Invalid topic', topicName);
    return;
  }

  const topicType = Object.values(ResourceType).find((type) => type === splitTopic[splitTopic.length - 1]);
  if (!topicType) {
    console.warn(`Invalid topic type ${topicName}`);
    return;
  }

  if (ignoreSelfTypes.includes(topicType) && iotMessage?.sender === userId) {
    console.log(`Ignoring own ${topicName} message`, iotMessage.eventType);
    return;
  }

  switch (topicType) {
    case ResourceType.Meetings:
      handleUpdate(iotMessage, queryClient);
      break;
    case ResourceType.Chime:
      handleChimeUpdate(iotMessage, queryClient);
      break;
    case ResourceType.Uploads:
      handleUploadUpdate(iotMessage, queryClient);
      break;
    case ResourceType.Patients:
      handleUpdate(iotMessage, queryClient);
      break
    case ResourceType.Organisation:
      handleUpdate(iotMessage, queryClient);
      break
    case ResourceType.Users:
      if (iotMessage.eventType === MessageEventType.Custom) {
        handleUserUpdate(iotMessage, queryClient);
      }
      else {
        handleUpdate(iotMessage, queryClient);
      }
      break
    default:
      handleOtherUpdate(iotMessage);
  }
}