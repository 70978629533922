import React, { useContext, useEffect } from "react";

import { MeetingStatus, useLocalVideo, useMeetingManager } from "amazon-chime-sdk-component-library-react";

import toast from 'react-hot-toast';

import { MeetingInfoContext } from "../../context/meeting-info-context";

import UploadFileButton from "./upload-file-button";
import { useUploadFile } from "../../store";


interface UploadImageButtonProps {
    restartVideo: boolean;
    capture?: boolean;
    showText?: boolean;
    reportImageUploaded?: () => void;
    children?: React.ReactNode;
}

const UploadImageButton: React.FC<UploadImageButtonProps> = ({
    restartVideo,
    reportImageUploaded,
    showText = true,
    capture = true,
    children
}: UploadImageButtonProps) => {

    const [uploading, setUploading] = React.useState(false);

    const { userId, meetingId, patientId } = useContext(MeetingInfoContext);

    const meetingManager = useMeetingManager();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const [wasVideoEnabled, setWasVideoEnabled] = React.useState(false);

    const canUpload = !uploading && meetingManager.meetingStatus === MeetingStatus.Succeeded;

    const onFileUploaded = () => {
        setUploading(false);

        toast.success('File uploaded successfully!');

        if (reportImageUploaded) {
            reportImageUploaded();
        }
    }

    const onFileFailedUpload = () => {
        setUploading(false);

        toast.error('Error uploading file');
    }

    const { mutate: createURL } = useUploadFile(meetingId, userId, patientId, onFileUploaded, onFileFailedUpload);

    const onFile = (file: File | null) => {

        if (!file) {
            return;
        }

        if (restartVideo && wasVideoEnabled) {
            waitToRestartVideo();
        }

        toast('Starting Upload');

        createURL(file)

        setUploading(true);
    }

    const onCancel = () => {
        console.log("~~~ Image uploader cancelled ~~~");
        waitToRestartVideo();
    }

    const onUploaderClick = () => {
        setWasVideoEnabled(isVideoEnabled);

        if (isVideoEnabled) {
            toggleVideo();
        }
    }

    const waitToRestartVideo = () => {

        console.log("~~~ waitToRestartVideo " + wasVideoEnabled);
        if (!wasVideoEnabled || isVideoEnabled) {
            return;
        }

        setWasVideoEnabled(false);

        setTimeout(() => {
            toggleVideo();
        }, 1000);
    }

    return (
        <UploadFileButton
            capture={capture}
            showText={showText}
            activeLabel="Upload Photo"
            inactiveLabel={uploading ? "Upload in progress" : "Cannot upload photo"}
            onFile={onFile}
            reportCancel={onCancel}
            onClick={onUploaderClick}
            active={canUpload}
            disabled={!canUpload}>
            {children}
        </UploadFileButton>
    );
}

export default UploadImageButton;