import { SxProps, Theme } from "@mui/material";

import TelehealthIconButton from "./telehealth-icon-button";
import { RouteType } from "../router";

interface NavigationIconButtonProps {
    navigationPath: RouteType;
    tooltip: string;
    icon: React.ReactNode;
    disabled?: boolean;
    sx?: SxProps<Theme>
}

const NavigationIconButton: React.FC<NavigationIconButtonProps> = ({
    navigationPath,
    tooltip,
    icon,
    disabled,
    sx,
}) => {

    return (
        <TelehealthIconButton
            tooltip={tooltip}
            disabled={disabled}
            icon={icon}
            href={navigationPath}
            onClick={() => { }}
            sx={sx}
        />
    );
}

export default NavigationIconButton;