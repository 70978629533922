import { Container, Grid, Skeleton, Stack, Typography } from "@mui/material";

import { useGetCurrentUser } from "../store";

import UserDetails from "../components/login/user-details";
import ChangePasswordCard from "../components/login/change-password-card";
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";


const AccountInfoPage: React.FC = () => {

    const { data: currentUser } = useGetCurrentUser()

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={5}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}
        >
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Container>
                {currentUser &&
                    <Grid container>
                        <Grid item xs={12} padding={1.5}>
                            <Typography variant="h3">Account Settings</Typography>
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <UserDetails user={currentUser} />
                        </Grid>
                        <Grid item xs={6} padding={1}>
                            <ChangePasswordCard />
                        </Grid>
                    </Grid>}
                {!currentUser && <Skeleton variant="rectangular" width={300} height={300} />}
            </Container>
        </Stack>
    );

}

export default AccountInfoPage;