import React from "react";

import { Stack } from "@mui/material";

import { v4 as uuidv4 } from "uuid";

import SelectAttendee from "./select-attendee";
import { useAddTemporaryUser, useGetCurrentUser } from "../../store";
import { User, UserType } from "../../types";
import toast from "react-hot-toast";

interface SelectOrAddAttendeeProps {
    organisationIds: string[];
    requiredIds: string[];
    selected: string[];
    disabled?: boolean;
    onSuccess?: (user?: User) => void;
    onFail?: () => void;
    onSubmit?: () => void;
    onSelect: (selected: string[]) => void
}

const SelectOrAddAttendee: React.FC<SelectOrAddAttendeeProps> = ({
    organisationIds,
    requiredIds,
    selected,
    disabled,
    onSuccess,
    onFail,
    onSubmit,
    onSelect,
}) => {

    const { data: currentUser } = useGetCurrentUser();
    const [addInProgress, setAddInProgress] = React.useState<boolean>(false);


    const onAddUserFromSelect = (userName: string, selected: string[]) => {
        const newUser: User = {
            userId: uuidv4(),
            organisationId: currentUser?.organisationId || "",
            fullName: userName,
            jobTitle: "",
            userType: UserType.Temporary,
            permissionSet: false,
        }

        console.log(newUser);

        setAddInProgress(true);
        addTemporaryUser(newUser);

        if (onSubmit) {
            onSubmit();
        }
    }

    const onAddSuccess = (user?: User) => {

        if (!user) {
            onAddError("User not found");
            return;
        }

        toast.success("User added successfully");

        if (onSuccess) {
            onSuccess(user);
        }

        const newSelected = [user?.userId, ...selected];

        onSelect(newSelected);
        setAddInProgress(false);
    }

    const onAddError = (err: any) => {
        console.log(err);

        toast.error("Failed to add user");

        if (onFail) {
            onFail();
        }

        setAddInProgress(false);
    }

    const { mutate: addTemporaryUser } = useAddTemporaryUser(onAddSuccess, onAddError);

    return (
        <SelectAttendee
            organisationIds={organisationIds}
            requiredIds={requiredIds}
            disabled={disabled || addInProgress}
            selected={selected}
            onSelect={onSelect}
            onAdd={onAddUserFromSelect} />
    )
}

export default SelectOrAddAttendee;