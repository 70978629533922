import React from "react";
import { useContext, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthContext } from "./context/auth-context";
import { UserType, MeetingJoinType } from "./types";

import MeetingPage from "./routes/meeting-page";
import ProtectedRoute from "./routes/private";
import Login from "./routes/login";
import CalendarPage from "./routes/calendar-page";
import ErrorPage from "./error";
import CallEnded from "./routes/call-ended";
import OrganisationAdminPage from "./routes/organisation-admin-page";
import OrganisationPage from "./routes/organisation-page";
import ForgotPasswordPage from "./routes/forgot-password-page";
import AccountInfoPage from "./routes/account-info-page";
import PatientPage from "./routes/patient-page";
import HelpPage from "./routes/help-page";
import ModelPage from "./routes/model-page";

export enum RouteType {
    Login = "/",
    Dashboard = "/dashboard",
    Account = "/account",
    Organisation = "/organisation",
    OrganisationAdmin = "/adminorganisations",
    Patient = "/patient",
    Help = "/help",
    Model = "/model",
    ForgotPassword = "/forgotpassword",
    CallEnded = "/callEnded",
    MetroMeeting = `/${MeetingJoinType.Metro}`,
    RemoteMeeting = `/${MeetingJoinType.Remote}`,
    HeadsetMeeting = `/${MeetingJoinType.Headset}`,
    PhoneMeeting = `/${MeetingJoinType.Phone}`,
    MobileMeeting = `/${MeetingJoinType.Mobile}`,
    Error = "*",
}

const Router = () => {

    const { tryGetStoredSession, isAuthenticated } = useContext(AuthContext);

    const [loading, setLoading] = React.useState(true);
    const [canShow, setCanShow] = React.useState(false);

    useEffect(() => {
        if (!loading || isAuthenticated) {
            return;
        }

        const waitForSessionCheck = async () => {
            const result = await tryGetStoredSession();
            setLoading(false);
            setCanShow(result.length > 0);
        };

        waitForSessionCheck();
    }, []);

    const browserRouter = createBrowserRouter([
        {
            path: RouteType.Login,
            element: <Login />,
            errorElement: <ErrorPage />,
        },
        {
            path: RouteType.Dashboard,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    authenticationPath={RouteType.Login}
                    requiredUserLevel={UserType.User}
                    outlet={<CalendarPage />} />
            ),
        },
        {
            path: RouteType.Account,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.User}
                    authenticationPath={RouteType.Login}
                    outlet={<AccountInfoPage />} />
            ),
        },
        {
            path: RouteType.OrganisationAdmin,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.OrgAdmin}
                    authenticationPath={RouteType.Login}
                    outlet={<OrganisationAdminPage />} />
            ),
        },
        {
            path: RouteType.Organisation,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.OrgAdmin}
                    authenticationPath={RouteType.Login}
                    outlet={<OrganisationPage />} />
            ),
        },
        {
            path: RouteType.Patient,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.User}
                    authenticationPath={RouteType.Login}
                    outlet={<PatientPage />} />
            ),
        },
        {
            path: RouteType.Help,
            element: <ProtectedRoute
                isLoading={loading}
                isAuthenticated={isAuthenticated || canShow}
                requiredUserLevel={UserType.User}
                authenticationPath={RouteType.Login}
                outlet={<HelpPage />} />

        },
        {
            path: RouteType.Model,
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    authenticationPath={RouteType.Login}
                    requiredUserLevel={UserType.User}
                    outlet={<ModelPage />} />
            ),
        },
        {
            path: RouteType.Login,
            element: <Login />,
        },
        {
            path: RouteType.ForgotPassword,
            element: <ForgotPasswordPage />,
        },
        {
            path: RouteType.MetroMeeting,
            element: <MeetingPage meetingType={MeetingJoinType.Metro} />,
        },
        {
            path: RouteType.RemoteMeeting,
            element: <MeetingPage meetingType={MeetingJoinType.Remote} />,
        },
        {
            path: RouteType.HeadsetMeeting,
            element: <MeetingPage meetingType={MeetingJoinType.Headset} />,
        },
        {
            path: RouteType.PhoneMeeting,
            element: <MeetingPage meetingType={MeetingJoinType.Phone} />,
        },
        {
            path: RouteType.MobileMeeting,
            element: <MeetingPage meetingType={MeetingJoinType.Mobile} />,
        },
        {
            path: RouteType.CallEnded,
            element: <CallEnded />,
        },
        {
            path: RouteType.Error,
            element: <ErrorPage />,
        }
    ],
        {
            future: {
                v7_relativeSplatPath: true,
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                //v7_startTransition: true,
                v7_skipActionErrorRevalidation: true,
            }
        }
    );

    return (
        <RouterProvider router={browserRouter} />
    );

}

export default Router;