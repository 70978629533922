import React, { useContext, useEffect } from "react";

import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

import { useGetPhotoUrls } from "../../store";
import { MeetingInfoContext } from "../../context/meeting-info-context";

import toast from "react-hot-toast";
import DownloadImageModal from "./download-image-modal";
import ViewModelModal from "../three/view-model-modal";
import { Stack } from "@mui/material";

interface DownloadImageProps {
    showText?: boolean;
}

const DownloadPhotoUrlModal: React.FC<DownloadImageProps> = ({
    showText = true
}) => {

    const [firstImagesSet, setFirstImagesSet] = React.useState(false);

    const [downloadPhotoURLs, setDownloadPhotoURLs] = React.useState<string[]>([]);

    const [downloadModelURLs, setDownloadModelURLs] = React.useState<string[]>([]);

    const meetingManager = useMeetingManager();

    const { userId } = useContext(MeetingInfoContext);

    const meetingId = meetingManager.meetingSessionConfiguration?.externalMeetingId || "";

    const { data: photoUrls } = useGetPhotoUrls(userId, meetingId);

    useEffect(() => {
        if (!photoUrls) {
            return;
        }

        console.log("Photo URLs", photoUrls);

        if (!firstImagesSet) {
            setFirstImagesSet(true);
        }

        if (photoUrls.length === 0) {
            return;
        }

        const newDownloadURLs = [...downloadPhotoURLs];
        const newModelURLs = [...downloadModelURLs];

        photoUrls.forEach((url: string) => {

            const isPhoto = url.includes("content-type=image")

            if (isPhoto && !downloadPhotoURLs.includes(url)) {
                newDownloadURLs.push(url);
            }

            if (!isPhoto && !downloadModelURLs.includes(url)) {
                newModelURLs.push(url);
            }
        });

        if (newDownloadURLs.length !== downloadPhotoURLs.length) {
            setDownloadPhotoURLs(newDownloadURLs);

            if (firstImagesSet) {
                toast.success("New image available for download")
            }
        }

        if (newModelURLs.length !== downloadModelURLs.length) {
            setDownloadModelURLs(newModelURLs);

            if (firstImagesSet) {
                toast.success("New model available for viewing")
            }
        }

    }, [photoUrls, meetingManager.meetingSessionConfiguration?.externalMeetingId]);

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyItems={"center"}
            spacing={2}
        >
            <DownloadImageModal
                showText={showText}
                downloadURLs={downloadPhotoURLs} />
            <ViewModelModal
                showText={showText}
                imageSources={downloadModelURLs} />
        </Stack>
    )
}

export default DownloadPhotoUrlModal;