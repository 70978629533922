import { Divider, Stack, Typography, useTheme } from "@mui/material";
import StraightenIcon from '@mui/icons-material/Straighten';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import TimelineIcon from '@mui/icons-material/Timeline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';

import ControlButton from "../control-button";
import { PropsWithChildren } from "react";


interface ModelControlPanelProps {
    showAreaTool: boolean;
    onAreaToolToggle: () => void;
    onMeasureToolToggle: () => void;
    onCenterModel: () => void;
    onUndo: () => void;
    onReset: () => void;
    canReset: boolean;
}

const ModelControlPanel: React.FC<PropsWithChildren<ModelControlPanelProps>> = ({
    showAreaTool,
    onAreaToolToggle,
    onMeasureToolToggle,
    onCenterModel,
    onReset,
    onUndo,
    canReset,
    children,
}) => {
    const theme = useTheme();

    return (
        <Stack
            direction={"column"}
            spacing={1}
            width={"fit-content"}
            alignItems={"center"}
            alignContent={"center"}>
            <Stack
                direction={"column"}
                spacing={1}
                width={"fit-content"}
                alignItems={"center"}
                alignContent={"center"}>
                <ControlButton
                    onClick={onAreaToolToggle}
                    showText={false}
                    active={showAreaTool}
                    activeColor={theme.palette.primary.light}
                    activeLabel="Turn off Area Tool"
                    inactiveLabel="Turn on Area Tool"
                    inactiveIcon={<TimelineIcon />}
                    activeIcon={<TimelineIcon />}
                    tooltipPlacement="right" />
                <ControlButton
                    onClick={onMeasureToolToggle}
                    showText={false}
                    active={!showAreaTool}
                    activeColor={theme.palette.primary.light}
                    activeLabel="Turn off Measure Tool"
                    inactiveLabel="Turn on Measure Tool"
                    inactiveIcon={<StraightenIcon />}
                    activeIcon={<StraightenIcon />}
                    tooltipPlacement="right" />
                <Divider />
                <ControlButton
                    onClick={onCenterModel}
                    showText={false}
                    active={false}
                    activeColor={theme.palette.primary.light}
                    activeLabel="Center Model"
                    inactiveLabel="Center Model"
                    inactiveIcon={<CenterFocusStrongIcon />}
                    tooltipPlacement="right" />
                <ControlButton
                    onClick={onUndo}
                    showText={false}
                    disabled={!canReset}
                    active={canReset}
                    activeColor={theme.palette.primary.light}
                    activeLabel={"Undo Last Point"}
                    inactiveLabel={"Undo Last Point"}
                    inactiveIcon={<RestartAltIcon />}
                    activeIcon={<RestartAltIcon />}
                    tooltipPlacement="right" />
                <ControlButton
                    onClick={onReset}
                    showText={false}
                    disabled={!canReset}
                    active={canReset}
                    activeColor={theme.palette.primary.light}
                    activeLabel={"Reset Points"}
                    inactiveLabel={"Reset Points"}
                    inactiveIcon={<CancelIcon />}
                    activeIcon={<CancelIcon />}
                    tooltipPlacement="right" />
                {children}
            </Stack>
        </Stack>
    );
};

export default ModelControlPanel;