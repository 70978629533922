import React, { useEffect } from "react";

import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useSearchParams } from "react-router-dom";

import { MeetingInfoContext } from "../context/meeting-info-context";

import MobileUpload from "./mobile-upload";
import DeviceMeeting from "./device-meeting";
import MeetingStatusDisplay from "../components/meeting/meeting-status-display";
import ToastJoin from "../components/meeting/toast-join";
import MobileMeeting from "./mobile-meeting";
import NormalMeetingDrawer from "../components/meeting/normal-meeting-drawer";
import RemoteUserMeeting from "../components/meeting/remote-user-meeting";
import { MeetingJoinType } from "../types";
import { useJoinMeeting } from "../store";

interface MeetingPageProps {
  meetingType: MeetingJoinType;
}

const MeetingPage: React.FC<MeetingPageProps> = ({
  meetingType = MeetingJoinType.Metro,
}: MeetingPageProps) => {

  const isMobile = navigator.userAgent.includes("Mobile");

  //exact dimensions of RealWare screen

  let widthMatches = Math.abs(window.screen.width - 853) < 10;
  widthMatches = widthMatches || (Math.abs(window.screen.width - 1003) < 10);

  let heightMatches = Math.abs(window.screen.height - 480) < 10;
  heightMatches = heightMatches || (Math.abs(window.screen.height - 564) < 10);

  const isRealWareDevice = widthMatches && heightMatches;

  if (meetingType === MeetingJoinType.Phone && isMobile) {
    if (isRealWareDevice) {
      meetingType = MeetingJoinType.Headset;
    }
  }
  else if (meetingType === MeetingJoinType.Headset && isMobile) {
    if (!isRealWareDevice) {
      meetingType = MeetingJoinType.Phone;
    }
  }

  const [searchParams] = useSearchParams();
  const meetingId = searchParams.get("meetingId") || "";
  const userId = searchParams.get("userId") || "";

  const [userName, setUserName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [patientId, setPatientId] = React.useState("");
  const [showSelf, setShowSelf] = React.useState(true);
  const [connected, setConnected] = React.useState(false);
  const [selectedAttendees, setSelectedAttendees] = React.useState<string[]>([]);
  const [showContentShare, setShowContentShare] = React.useState(false);
  const [otherAttendees, setOtherAttendees] = React.useState<string[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const meetingManager = useMeetingManager();

  const onJoinSuccess = (data: any) => {

    if (data?.fullName) {
      setUserName(data?.fullName);
    }

    if (data?.jobTitle) {
      setJobTitle(data?.jobTitle);
    }

    if (data?.patientId) {
      setPatientId(data?.patientId);
    }
  }

  const onJoinError = (error: any) => {
    console.log("Join error", error);
    setErrorMessage(error.message);
  }

  const { mutate: joinMeeting } = useJoinMeeting(meetingManager, onJoinSuccess, onJoinError);

  useEffect(() => {
    if (errorMessage != null) {
      return;
    }

    if (!meetingId || !userId) {
      setErrorMessage("Missing meeting or user id");
      return;
    }

    const values = {
      meetingId,
      userId,
      meetingType,
    }

    joinMeeting(values);
  }, [meetingId, userId]);

  return (
    <MeetingInfoContext.Provider value={{
      meetingId,
      userId,
      patientId,
      setPatientId,
      userName,
      setUserName,
      jobTitle,
      setJobTitle,
      meetingType,
      errorMessage,
      setErrorMessage,
      connected,
      setConnected,
      showSelf,
      setShowSelf,
      selectedAttendees,
      otherAttendees,
      setOtherAttendees,
      setSelectedAttendees,
      showContentShare,
      setShowContentShare,
    }}>
      <MeetingStatusDisplay connected={connected} setConnected={setConnected} />
      <ToastJoin />
      {meetingType === MeetingJoinType.Headset ? <DeviceMeeting /> : null}
      {meetingType === MeetingJoinType.Phone ? <MobileUpload /> : null}
      {meetingType === MeetingJoinType.Metro ? <NormalMeetingDrawer /> : null}
      {meetingType === MeetingJoinType.Mobile ? <MobileMeeting /> : null}
      {meetingType === MeetingJoinType.Remote ? <RemoteUserMeeting /> : null}
    </MeetingInfoContext.Provider>
  );
};

export default MeetingPage;
