import { DeviceLabels, MeetingManager } from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { AttendeeResponse } from "amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types";

import { MeetingJoinType } from "../types"

const BASE_URL = import.meta.env.VITE_API_URL as string + "/chime";

const sendJoinRequest = async (
    meetingId: string,
    attendeeId: string,
    meetingType: MeetingJoinType
): Promise<any> => {

    let response = null;

    try {
        response = await fetch(`${BASE_URL}/join`, {
            method: "POST",
            body: JSON.stringify({
                meetingId,
                attendeeId,
                meetingType,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    catch (error) {
        console.log(error);
        return null;
    }

    if (!response.ok) {

        // get the message from the response's body
        const responseText = await response.json();

        throw new Error(`Error (${response.status}): ${responseText.message}`);
    }

    return response.json();
};

export const joinMeeting = async (meetingManager: MeetingManager, meetingId: string, attendeeId: string, meetingType: MeetingJoinType): Promise<any | null> => {

    // Fetch the meeting and attendee data from your server application
    const response = await sendJoinRequest(
        meetingId,
        attendeeId,
        meetingType,
    );

    if (!response) {
        console.log("Error joining meeting")
        return null;
    }

    meetingManager.getAttendee = getAttendee;

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
        response.JoinInfo.Meeting,
        response.JoinInfo.Attendee
    );

    let deviceLabels = DeviceLabels.AudioAndVideo;

    switch (response.deviceLabels) {
        case "Video":
            deviceLabels = DeviceLabels.Video;
            break;
        case "Audio":
            deviceLabels = DeviceLabels.Audio;
            break;
        case "AudioAndVideo":
            deviceLabels = DeviceLabels.AudioAndVideo;
            break;
        default:
            deviceLabels = DeviceLabels.None;
            break;
    }

    const options = {
        deviceLabels: deviceLabels,
    };

    // Use the join API to create a meeting session using the above data
    await meetingManager.join(meetingSessionConfiguration, options);

    // Start the session to join the meeting
    await meetingManager.start();

    return response;
};

const getAttendee = (chimeAttendeeId: string, externalUserId?: string | undefined): Promise<AttendeeResponse> => {

    if (!externalUserId) {
        return Promise.resolve({
            name: "???",
            actualUserId: chimeAttendeeId,
            userType: "",
        });
    }

    const results = getMeetingTypeFromExternalId(externalUserId);

    return Promise.resolve(results);
}

export const getMeetingTypeFromExternalId = (externalUserId: string) => {

    const split = externalUserId.split("#");

    if (split.length < 2) {
        return {
            name: externalUserId,
            actualUserId: externalUserId,
            userType: "",
        };
    }

    if (split.length < 3) {
        return {
            name: split[1],
            actualUserId: split[0],
            userType: "",
        }
    }

    let userType = split[2] || "";

    if (userType.length > 0) {
        let found = ""
        for (const type of Object.values(MeetingJoinType)) {
            if (type.includes(userType)) {
                found = type;
                break;
            }
        }

        userType = found;
    }

    return {
        name: split[1],
        actualUserId: split[0],
        userType: userType,
    };
}