import React from "react";

import { useSearchParams } from "react-router-dom";

import { Container, Skeleton, Stack } from "@mui/material";

import { useGetCurrentUser, useGetOrganisation } from "../store";
import { Organisation, UserType } from "../types";

import EditOrganisationPage from "../components/organisation/edit-organisation-page";
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";
import PatientDisplay from "../components/patients/patient-display";


const OrganisationPage: React.FC = () => {

    const [searchParams] = useSearchParams();
    const organisationId = searchParams.get("organisationId") || "";

    const { data: currentUser } = useGetCurrentUser()
    const { data: userOrganisation } = useGetOrganisation(currentUser?.organisationId || "");
    const { data: parameterOrganisation } = useGetOrganisation(organisationId);

    let finalOrganisation: Organisation | null = null;

    if (currentUser) {
        if (currentUser?.userType === UserType.SysAdmin && parameterOrganisation) {
            finalOrganisation = parameterOrganisation;
        }
        else if (userOrganisation) {
            finalOrganisation = userOrganisation;
        }
    }


    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={2}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}>
            <NavigationTelehealthToolbar currentUser={currentUser} />
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    {finalOrganisation &&
                        <EditOrganisationPage
                            finalOrganisation={finalOrganisation} />}
                    {!finalOrganisation &&
                        <Skeleton width={"100%"} height={"100%"} />}
                    <PatientDisplay />
                </Stack>
            </Container>
        </Stack >
    )
}

export default OrganisationPage;