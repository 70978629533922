import { PropsWithChildren } from "react";

import { Stack } from "@mui/material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

import { useLocation } from 'react-router-dom'

import { User, userHasPermission, UserType } from "../types"

import TelehealthToolbar from "./telehealth-toolbar"
import CalendarOptionsDropdown from "./calendar/calendar-options-dropdown"
import NavigationIconButton from "./navigation-icon-button";
import ClearCallLogo from "./clear-call-logo";
import { RouteType } from "../router";

interface NavigationTelehealthToolbarProps {
    currentUser?: User
}

const NavigationTelehealthToolbar: React.FC<PropsWithChildren<NavigationTelehealthToolbarProps>> = ({
    currentUser,
    children
}) => {

    const location = useLocation();

    const page = location.pathname

    let path = RouteType.Error
    let buttonLabel = ""
    let showOrganisation = false

    if (currentUser && currentUser.userType === UserType.OrgAdmin) {
        path = RouteType.Organisation
        buttonLabel = "Organisation Settings"
        showOrganisation = page !== RouteType.Organisation
    }

    if (currentUser && currentUser.userType === UserType.SysAdmin) {
        path = RouteType.OrganisationAdmin
        buttonLabel = 'Organisations'
        showOrganisation = page !== RouteType.OrganisationAdmin
    }

    return (
        <TelehealthToolbar >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width={"100vw"}
                padding={2}>
                <ClearCallLogo size={40} />
                <Stack
                    id="navigation-toolbar-stack"
                    direction="row"
                    spacing={2}
                    alignItems={"center"}
                    justifyItems={"center"}
                    height={"100%"}>
                    <Stack direction="row" spacing={0} height={"100%"}>
                        {showOrganisation &&
                            userHasPermission(UserType.OrgAdmin, currentUser) &&
                            <NavigationIconButton
                                tooltip={buttonLabel}
                                icon={<SupervisorAccountIcon sx={{
                                    color: "#055480",
                                }} />}
                                navigationPath={path}
                            />}
                        {page !== RouteType.Dashboard &&
                            <NavigationIconButton
                                tooltip="Calendar"
                                icon={<CalendarMonthIcon sx={{
                                    color: "#055480",
                                }} />}
                                navigationPath={RouteType.Dashboard}
                            />}
                        {page === RouteType.Dashboard &&
                            <NavigationIconButton
                                tooltip="View 3d Model"
                                icon={<ViewInArIcon sx={{
                                    color: "#055480",
                                }} />}
                                navigationPath={RouteType.Model}
                            />}
                    </Stack>
                    {children}
                    <CalendarOptionsDropdown
                        currentUser={currentUser} />
                </Stack>
            </Stack>
        </TelehealthToolbar>
    )
}

export default NavigationTelehealthToolbar