const uploadURL = import.meta.env.VITE_API_URL as string + "/photos";

const uploadPath = "upload";
const downloadPath = "download";

const createUploadUrl = async (
    userId: string,
    meetingId: string,
    photoName: string,
): Promise<any> => {

    const uploadApiUrl = `${uploadURL}/${uploadPath}`;

    const params = {
        userId: userId,
        photoName: photoName,
        meetingId: meetingId,
    };

    const response = await fetch(uploadApiUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    });

    if (!response.ok) {
        console.error("Error creating upload url", response);
        throw new Error("Network response was not ok");
    }

    return response.json();
};

const createDownloadLink = async (
    userId: string,
    meetingId: string,
    patientId: string,
    fileName: string,
): Promise<string> => {

    const downloadAPIUrl = `${uploadURL}/${downloadPath}`;

    const timeString = new Date().toLocaleTimeString([], { hour: "numeric", minute: "2-digit", hour12: false })
    const dateString = new Date().toLocaleDateString()

    const downloadName = `${dateString} ${timeString}`.split("/").join("-")

    const body = {
        meetingId,
        userId,
        patientId,
        fileName,
        downloadName,
    }

    const response = await fetch(downloadAPIUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        console.error("Error creating download link", response);
        throw new Error("Network response was not ok");
    }

    const downloadURL = await response.json();

    return downloadURL.url;
}

export const uploadGivenFile = async (
    userId: string,
    meetingId: string,
    file: File
) => {

    // get url to upload image
    const createResponse = await createUploadUrl(userId, meetingId, file.name);

    if (createResponse instanceof Error) {
        console.error("Error getting upload url", createResponse);
        throw new Error("Error getting upload url");
    }

    const url = createResponse.url;
    const uploadPhotoName = createResponse.uploadPhotoName;
    const array = await file.arrayBuffer();


    //remove metadata from image

    let blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' })

    let finalData: Blob | null = null;

    try {
        finalData = await new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(blobData);
            image.onload = (e) => {
                console.log("Image loaded");
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(image, 0, 0, image.width, image.height);
                ctx?.canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    }
                    else {
                        reject("Error converting to blob");
                    }
                }, 'image/jpeg', 1);
            };
        });
    } catch (e) {
        console.error("Error converting to blob", e);
        throw new Error("Error converting to blob");
    }

    if (finalData == null) {
        console.error("Error converting to blob");
        throw new Error("Error converting to blob");
    }

    console.log("urls", url)

    const result = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/jpeg',
        },
        body: finalData
    })

    if (!result.ok) {
        console.error("Error uploading image", result);
        throw new Error("Network response was not ok");
    }

    return uploadPhotoName;
};

export const uploadModel = async (
    userId: string,
    meetingId: string,
    model: any,
    modelName: string,
) => {

    const createResponse = await createUploadUrl(userId, meetingId, modelName);

    if (createResponse instanceof Error) {
        console.error("Error getting upload url", createResponse);
        throw new Error("Error getting upload url");
    }

    const url = createResponse.url;
    const uploadPhotoName = createResponse.uploadPhotoName;

    const array = await model.arrayBuffer();

    const result = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/ply',
        },
        body: array
    })

    if (!result.ok) {
        console.error("Error uploading model", result);
        throw new Error("Network response was not ok");
    }

    return uploadPhotoName;
};

export const uploadFileAndCreateDownloadURL = async (image: File, userId: string, meetingId: string, patientId: string): Promise<string> => {

    const isModel = image.name.endsWith(".ply");

    const uploadName = isModel ?
        await uploadModel(userId, meetingId, image, image.name) :
        await uploadGivenFile(userId, meetingId, image);

    return await createDownloadLink(userId, meetingId, patientId, uploadName);
}

export const downloadImages = async (downloadURLs: string[]) => {
    downloadURLs.forEach((downloadURL) => {
        downloadImage(downloadURL);
    })
}

export const downloadImage = async (downloadURL: string) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
    img.src = downloadURL;
    img.onload = () => {
        // create Canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        // create a tag
        const a = document.createElement('a');

        const now = new Date();

        a.download = `${now.toDateString()}.png`;
        a.href = canvas.toDataURL('image/png');
        a.click();
    }
}