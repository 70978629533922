import { MeetingJoinType } from "../../types";

const baseURL = import.meta.env.VITE_WEBSITE_URL as string;

export const createURL = (type: MeetingJoinType, meetingId: string, userId: string): string => {

    const typeString = `${type}`.toLowerCase();

    const url = new URL(`${baseURL}/${typeString}`);

    url.searchParams.append("meetingId", meetingId);
    url.searchParams.append("userId", userId);

    return url.toString();
};