import React from "react";

import ViewInArIcon from '@mui/icons-material/ViewInAr';

import ModelPanel from "./model-panel";
import DownloadBar from "../download-bar";
import TelehealthDialogue from "../telehealth-dialogue";
import UnseenItemButton from "../upload/unseen-item-button";

interface ViewModalProps {
    imageSources: string[];
    showText?: boolean;
}

const ViewModelModal: React.FC<ViewModalProps> = ({
    imageSources,
    showText = true
}) => {

    const linkRef = React.useRef<HTMLAnchorElement>(null);

    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const [seenImages, setSeenImages] = React.useState<number[]>([]);

    const [modelOpen, setModelOpen] = React.useState(false);

    const unseenImages = imageSources.length - seenImages.length;

    const currentImage = (currentImageIndex > -1 && currentImageIndex < imageSources.length)
        ? imageSources[currentImageIndex] : null

    const currentUrl = currentImageIndex < 0 || currentImageIndex >= imageSources.length ? "" : imageSources[currentImageIndex];

    const modelLabel = "Models " +
        (imageSources.length > 1 ?
            ` (${currentImageIndex + 1}/${imageSources.length})`
            : "");


    const selectCurrentIndex = (index: number) => {
        if (!seenImages.includes(index)) {
            setSeenImages([...seenImages, index]);
        }

        setCurrentImageIndex(index);
    }

    const openModal = () => {
        setModelOpen(true);

        const firstUnseenIndex = getFirstUnseenIndex();

        setCurrentImageIndex(firstUnseenIndex);

        if (!seenImages.includes(firstUnseenIndex)) {
            setSeenImages([...seenImages, firstUnseenIndex]);
        }
    }

    const closeModal = () => {
        setModelOpen(false);
    }

    const acceptDownload = () => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    }

    const getFirstUnseenIndex = () => {
        let firstUnseenIndex = currentImageIndex;

        for (let i = 0; i < imageSources.length; i++) {
            if (!seenImages.includes(i)) {
                firstUnseenIndex = i;
                break;
            }
        }

        return firstUnseenIndex;
    }

    return (
        <>
            <UnseenItemButton
                total={imageSources.length}
                typeName="image"
                label="View and download images"
                unseen={unseenImages}
                showText={showText}
                icon={<ViewInArIcon />}
                onClick={openModal} />
            <TelehealthDialogue
                title={modelLabel}
                open={modelOpen}
                onClose={closeModal}
                maxWidth={"xl"}>
                <ModelPanel
                    modelUrl={currentUrl}
                    patientId="points" >
                    <DownloadBar
                        tooltip={"Download model"}
                        onAccept={acceptDownload}
                        currentOptionIndex={currentImageIndex}
                        optionsLength={imageSources?.length || 0}
                        onNavigate={selectCurrentIndex} />
                </ModelPanel>
            </TelehealthDialogue >
            {currentImage && <a href={currentImage} ref={linkRef} download="image.jpg" />}
        </>
    );
}

export default ViewModelModal;