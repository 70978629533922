import { Meeting, MeetingJoinInfo, MeetingType } from "../types";

const URL = import.meta.env.VITE_API_URL as string + "/meeting";

const getMeeting = async (
    authToken: string,
    meetingId: string
): Promise<Meeting | null> => {

    if (meetingId.length == 0) {
        return null;
    }

    let response = null;
    try {
        response = await fetch(`${URL}/${meetingId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

    } catch (error) {
        console.log(error);
        throw error as Error;
    }

    if (response.status === 404) {
        console.warn("Meeting not found");
        return null;
    }

    if (!response.ok) {
        throw new Error(response.status + " Network response was not ok");
    }

    const data = await response.json();

    if (!data) {
        return null;
    }

    let realStartTime = undefined

    if (data?.realStartTime) {
        realStartTime = new Date(data.realStartTime);
    }

    const meeting: Meeting = {
        meetingId: data.meetingId,
        meetingName: data.meetingName,
        chimeJoinId: data.chimeJoinId,
        realStartTime,
        startTime: new Date(data.startTime),
        endTime: new Date(data.endTime),
        attendeesId: data.attendeesId,
        confirmedAttendeesId: data.confirmedAttendeesId || [],
        joinedIds: data.joinedIds || [],
        organisationIds: data.organisationIds || [],
        patientId: data.patientId || "",
        meetingType: data.meetingType,
    }

    return meeting;
};

const deleteMeeting = async (
    authToken: string,
    meeting: Meeting
): Promise<string | Error> => {
    try {
        const response = await fetch(`${URL}/${meeting.meetingId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
};

// api call to get Meetings
const listMeetings = async (
    authToken: string,
): Promise<Meeting[]> => {

    try {
        const response = await fetch(`${URL}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const meetingList: Meeting[] = data.map((meeting: any) => {

            let realStartTime = undefined

            if (meeting?.realStartTime) {
                realStartTime = new Date(meeting.realStartTime);
            }

            const convertedMeeting: Meeting = {
                meetingId: meeting.meetingId,
                meetingName: meeting.meetingName,
                chimeJoinId: meeting.chimeJoinId,
                realStartTime,
                startTime: new Date(meeting.startTime),
                endTime: new Date(meeting.endTime),
                attendeesId: meeting.attendeesId,
                confirmedAttendeesId: meeting.confirmedAttendeesId || [],
                joinedIds: meeting.joinedIds || [],
                organisationIds: meeting.organisationIds || [],
                patientId: meeting.patientId || "",
                meetingType: meeting.meetingType,
            }

            return convertedMeeting;
        })

        return meetingList

    } catch (error) {
        console.log(error);
        return [];
    }
};

const addMeeting = async (
    authToken: string,
    meeting: Meeting
): Promise<string | Error> => {

    try {
        const response = await fetch(`${URL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(meeting),
        });

        if (!response.ok) {
            if (response.status === 409) {
                // Handle the conflict error specifically
                return response.json().then((data) => {
                    throw new Error(data.message);
                });
            } else {
                throw new Error("Network response was not ok");
            }
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
};

const updateMeeting = async (
    authToken: string,
    values: Meeting
): Promise<string | null> => {
    try {
        const response = await fetch(`${URL}/${values.meetingId}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        });

        if (!response.ok) {
            if (response.status === 409) {
                // Handle the conflict error specifically
                return response.json().then((data) => {
                    throw new Error(data.message);
                });
            } else {
                throw new Error(response.status + " Network response was not ok");
            }
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const updateAttendees = async (
    authToken: string,
    meetingId: string,
    attendeesId: string[]
): Promise<string | Error> => {

    try {
        const response = await fetch(`${URL}/${meetingId}/attendees`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ attendeesId }),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return response.json();
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getPhotoUrls = async (
    authToken: string,
    userId: string,
    meetingId: string
): Promise<string[]> => {

    let photoUrls: string[] = [];
    try {
        const response = await fetch(`${URL}/${meetingId}/${userId}/photoUrl`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        photoUrls = await response.json();

    } catch (error) {
        console.log(error);
        throw error as Error;
    }

    return photoUrls;
}

const getJoinedAttendees = async (
    authToken: string,
    meetingId: string
): Promise<MeetingJoinInfo> => {

    let responseJson: any
    try {
        const response = await fetch(`${URL}/${meetingId}/joinIds`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        responseJson = await response.json();

        return {
            meetingId: responseJson.meetingId,
            joinedIds: responseJson.joinedIds,
            attendeesId: responseJson.attendeesId,
            confirmedAttendeesId: responseJson.confirmedAttendeesId,
            startTime: responseJson.startTime ? new Date(responseJson.startTime) : undefined,
        }

    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getMeetingsWithIds = async (authToken: string, meetingIds: string[], showDeleted: boolean): Promise<(Meeting | null)[]> => {

    const meetings: (Meeting | null)[] = await listMeetings(authToken);

    const filtered = meetings.filter((meeting) => {

        if (!meeting) {
            return false;
        }

        if (!showDeleted) {
            if (meeting.meetingType === MeetingType.Cancelled) {
                return false;
            }
        }

        return meetingIds.includes(meeting.meetingId);
    });

    return filtered;
}

const MeetingsAPIService = {
    getMeeting,
    listMeetings,
    addMeeting,
    deleteMeeting,
    updateMeeting,
    updateAttendees,
    getMeetingsWithIds,
    getPhotoUrls,
    getJoinedAttendees,
};

export default MeetingsAPIService;
