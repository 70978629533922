import React from "react"

import { Stack, Typography } from "@mui/material"

import { Meeting, Organisation, User } from "../../types"
import { CreateButtonInfo } from "./create-button-info"

import DialogueJoinMeetingButton from "../upload/dialogue-join-meeting-button"
import AttendeeOrganisationGrid from "./attendee-organisation-grid"
import CustomTag from "../custom-tag"
import { useGetJoinInfo } from "../../store"

interface MeetingInfoProps {
  meeting: Meeting
  currentUser?: User
  userOrganisation?: Organisation | null
}

const MeetingInfo: React.FC<MeetingInfoProps> = ({
  meeting,
  currentUser,
  userOrganisation,
}: MeetingInfoProps) => {

  const { data: joinInfo } = useGetJoinInfo(meeting?.meetingId || "");

  const buttonInfo = CreateButtonInfo(meeting, joinInfo, currentUser, userOrganisation);

  const createDateLabel = (date: Date) => {

    const timeZone = date.toLocaleTimeString("en-AU", { timeZoneName: "short" }).split(" ")[2]
    const timeZoneString = ` (${timeZone})`

    const timeLocalString = date.toLocaleTimeString("en-AU", { hour: "numeric", minute: "numeric", hour12: true })

    // if today
    if (date.toDateString() === new Date().toDateString()) {
      return timeLocalString + timeZoneString
    }

    const atTimeString = " at " + timeLocalString + timeZoneString

    const dateNumber = date.getDate()

    let dayString = dateNumber.toString()

    if (dateNumber === 1) {
      dayString += "st"
    }
    else if (dateNumber === 2) {
      dayString += "nd"
    }
    else if (dateNumber === 3) {
      dayString += "rd"
    }
    else {
      dayString += "th"
    }

    const dayMonthString = dayString + " " + date.toLocaleDateString("en-AU", { month: "long" })

    // if this month
    if (date.getMonth() === new Date().getMonth()) {
      return dayMonthString + atTimeString
    }

    // if this year
    if (date.getFullYear() === new Date().getFullYear()) {
      return dayMonthString + atTimeString
    }

    return dayMonthString + ` ${date.getFullYear()} ` + atTimeString
  }

  const dateLabel = createDateLabel(meeting?.startTime || new Date())

  const allAttendees = meeting?.attendeesId || []

  if (joinInfo && joinInfo.joinedIds) {
    for (const attendeeId of joinInfo.joinedIds) {
      if (!allAttendees.includes(attendeeId)) {
        allAttendees.push(attendeeId)
      }
    }
  }

  return (
    <>
      <Stack>
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"left"}
          alignItems={"center"}>
          <Typography variant={"h4"}>Patient:</Typography>
          <CustomTag
            text={meeting?.patientId || ""}
            color="#e6e6e6"
            sx={{
              height: "fit-content",
              width: "fit-content",
              paddingY: "0.2rem",
              paddingX: "0.4rem",
            }} />
        </Stack>
        <Typography variant={"h5"}>Start Time: {dateLabel}</Typography>
      </Stack>
      <Stack
        direction={"column"}
        alignContent={"center"}
        justifyContent={"centre"}
        spacing={1}>
        <DialogueJoinMeetingButton
          canJoinNow={buttonInfo.canJoinMeeting}
          meeting={meeting}
          currentUser={currentUser}
          joinInfo={joinInfo || undefined}
          canJoinMessage={buttonInfo.canJoinMessage}
          buttonType={buttonInfo.buttonType} />
        <Typography variant={"h5"}>Attendees:</Typography>
        <AttendeeOrganisationGrid
          direction={"column"}
          meeting={meeting}
          joinInfo={joinInfo || undefined} />
      </Stack>
    </>
  )
}

export default MeetingInfo
