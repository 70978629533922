import React from "react";

import { Stack, Skeleton, } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { useAddMeeting, useDeleteMeeting, useGetCurrentUser, useGetOrganisation, useListOrganisations, useListUsers, useMeetingList, useUpdateMeeting } from "../store";

import EventCalendar from "../components/calendar/EventCalendar";
import TelehealthStyledButton from "../components/telehealth-styled-button";
import { OrganisationType } from "../types";
import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar";
import toast from "react-hot-toast";

const CalendarPage: React.FC = () => {

  const toastMeetingSuccess = () => {
    toast.success("Meeting successfully added")
  }

  const toastMeetingError = () => {
    toast.error("Error adding meeting")
  }

  const toastMeetingUpdateSuccess = () => {
    toast.success("Meeting successfully updated")
  }

  const toastMeetingUpdateError = () => {
    toast.error("Error updating meeting")
  }

  const toastCancelMeetingSuccess = () => {
    toast.success("Meeting successfully cancelled")
  }

  const toastCancelMeetingError = () => {
    toast.error("Error cancelling meeting")
  }

  const { mutate: addMeeting } = useAddMeeting(toastMeetingSuccess, toastMeetingError);
  const { mutate: deleteMeeting } = useDeleteMeeting(toastCancelMeetingSuccess, toastCancelMeetingError);
  const { mutate: updateMeeting } = useUpdateMeeting(toastMeetingUpdateSuccess, toastMeetingUpdateError);

  const { data: meetingList, isLoading: loadingMeeting } = useMeetingList();
  const { data: currentUser, isLoading: loadingCurrentUser } = useGetCurrentUser()
  const { data: currentOrganisation, isLoading: loadingCurrentOrganisation } = useGetOrganisation(currentUser?.organisationId || "")

  const { data: organisationList, isLoading: loadingOrganisationList } = useListOrganisations()

  const loadingData = loadingMeeting || loadingCurrentUser || loadingCurrentOrganisation || loadingOrganisationList

  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false)

  const addButtonClick = () => {
    setAddModalOpen(true)
  }

  const closeAddModal = () => {
    setAddModalOpen(false)
  }

  return (
    <Stack
      justifyContent="top"
      alignItems="center"
      spacing={1}
      sx={{
        height: "100vh",
      }}
    >
      <NavigationTelehealthToolbar currentUser={currentUser} >
        {currentOrganisation?.type === OrganisationType.Metro &&
          <TelehealthStyledButton
            onClick={addButtonClick}
            label="Add Meeting"
            showText={true}
            icon={<AddIcon />}
            variant="contained"
            spacing={0.5}
            sx={{
              paddingX: 1.5,
            }}
          />}
      </NavigationTelehealthToolbar>
      {loadingData ?
        <Skeleton width={"95vw"} height={"95vh"}></Skeleton> :
        <EventCalendar
          meetingList={meetingList || []}
          organisationList={organisationList || []}
          currentUser={currentUser}
          addIsOpen={addModalOpen}
          closeAddModal={closeAddModal}
          currentOrganisation={currentOrganisation}
          addMeeting={addMeeting}
          deleteMeeting={deleteMeeting}
          updateMeeting={updateMeeting} />
      }
    </Stack >
  )
}

export default CalendarPage;
