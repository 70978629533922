import React, { useEffect, useState } from 'react';

import { mqtt5 } from 'aws-iot-device-sdk-v2';

import { useGetCurrentUser, useWaitToConnect } from '../store';
import { closeConnection } from './iot-service';

const ConnectIoT: React.FC<React.PropsWithChildren> = ({ children }) => {

  const [clientConnection, setClientConnection] = useState<mqtt5.Mqtt5Client | null>(null);
  const [lastUserId, setLastUserId] = useState<string | null>(null);

  const { data: currentUser } = useGetCurrentUser();
  const { data: connection } = useWaitToConnect(currentUser);

  useEffect(() => {
    if (connection != clientConnection) {
      closeConnection(clientConnection, 'Disconnecting from AWS IoT due to connection change');
      setClientConnection(connection || null);
    }

    if (lastUserId !== currentUser?.userId) {
      setLastUserId(currentUser?.userId || null);
    }

  }, [currentUser, connection]);

  return (
    <>
      {children}
    </>
  );
};

export default ConnectIoT