import React from "react"

import { Divider, Stack, Typography } from "@mui/material"

import { Meeting } from "../../types"
import { useGetCurrentUser, useGetJoinInfo, useGetOrganisation } from "../../store"

import { CreateButtonInfo } from "./create-button-info"
import JoinMeetingButton from "../upload/join-meeting-button"
import AttendeeOrganisationGrid from "./attendee-organisation-grid"
import CustomTag from "../custom-tag"

interface MeetingInfoAgendaProps {
    event: Meeting
}

const MeetingInfoAgenda: React.FC<MeetingInfoAgendaProps> = ({
    event,
}: MeetingInfoAgendaProps) => {

    const { data: user } = useGetCurrentUser();

    const { data: userOrganisation } = useGetOrganisation(user?.organisationId || "");

    const { data: joinInfo } = useGetJoinInfo(event?.meetingId || "");

    const buttonInfo = CreateButtonInfo(event, joinInfo, user, userOrganisation);

    return (
        <Stack
            id="meeting-info-agenda"
            spacing={1}
            direction={"row"}
            justifyContent={"space-between"}
            alignContent={"center"}
            height={"100%"}
            width={"100%"}>
            <Stack
                direction={"row"}
                spacing={1}
                height={"100%"}
                alignItems={"center"}
                justifyItems={"center"}>
                <Typography variant={"h5"}>Patient:</Typography>
                <CustomTag
                    text={event?.patientId || ""}
                    color="#e6e6e6" />
                <Divider orientation="vertical" flexItem />
                <Typography variant={"h5"}>Attendees:</Typography>
                <AttendeeOrganisationGrid
                    meeting={event}
                    direction="row"
                    joinInfo={joinInfo || undefined} />
            </Stack>
            <Stack
                direction={"row"}
                spacing={3}
                alignItems={"center"}>
                {!buttonInfo.canJoinMeeting &&
                    <Typography variant={"body1"}>
                        {buttonInfo.canJoinMessage}
                    </Typography>}
                {buttonInfo.canJoinMeeting &&
                    <>
                        <Typography
                            variant={"body1"}>
                            <b>{buttonInfo.canJoinMessage}</b>
                        </Typography>
                        {user &&
                            <JoinMeetingButton
                                meeting={event}
                                joinedUsers={joinInfo?.joinedIds || []}
                                userId={user?.userId || ""}
                                meetingType={buttonInfo.buttonType} />}
                    </>}
            </Stack>
        </Stack >
    )
}

export default MeetingInfoAgenda