import { Vector2, Vector3 } from "three";
import { Matrix } from 'ml-matrix';
import PCA from 'pca-js';

// Function to check if two line segments intersect
const doLineSegmentsIntersect = (firstStart: Vector2, firstEnd: Vector2, secondStart: Vector2, secondEnd: Vector2): boolean => {

    const orientation = (first: Vector2, second: Vector2, third: Vector2): number => {
        const val = (second.y - first.y) * (third.x - second.x) - (second.x - first.x) * (third.y - second.y);

        // collinear
        if (val === 0) {
            return 0;
        }

        // clock or counterclock wise
        return (val > 0) ? 1 : 2;
    };

    const onSegment = (first: Vector2, second: Vector2, third: Vector2): boolean => {
        return second.x <= Math.max(first.x, third.x) &&
            second.x >= Math.min(first.x, third.x) &&
            second.y <= Math.max(first.y, third.y) &&
            second.y >= Math.min(first.y, third.y);
    };

    const firstOrientation = orientation(firstStart, firstEnd, secondStart);
    const secondOrientation = orientation(firstStart, firstEnd, secondEnd);
    const thirdOrientation = orientation(secondStart, secondEnd, firstStart);
    const fourthOrientation = orientation(secondStart, secondEnd, firstEnd);

    if (firstOrientation !== secondOrientation && thirdOrientation !== fourthOrientation) {
        return true;
    }

    if (firstOrientation === 0 && onSegment(firstStart, secondStart, firstEnd)) {
        return true;
    }

    if (secondOrientation === 0 && onSegment(firstStart, secondEnd, firstEnd)) {
        return true;
    }

    if (thirdOrientation === 0 && onSegment(secondStart, firstStart, secondEnd)) {
        return true;
    }

    if (fourthOrientation === 0 && onSegment(secondStart, firstEnd, secondEnd)) {
        return true;
    }

    return false;
};

export const willBeSelfIntersecting = (points: Vector2[]): boolean => {

    if (points.length < 4) {
        return false;
    }

    const lastPoint = points[points.length - 1];
    const secondLastPoint = points[points.length - 2];

    for (let i = 0; i < points.length - 3; i++) {
        if (doLineSegmentsIntersect(points[i], points[i + 1], secondLastPoint, lastPoint)) {
            return true;
        }
    }

    for (let i = 2; i < points.length - 2; i++) {
        if (doLineSegmentsIntersect(points[i], points[i + 1], points[0], lastPoint)) {
            return true;
        }
    }

    return false;
}

// Function to find the best shared plane for a set of points
export const findBestPlane = (points: Vector3[]): { normal: Vector3, point: Vector3 } => {
    // Convert points to a matrix
    const data = points.map(p => [p.x, p.y, p.z]);
    const matrix = new Matrix(data);

    // Perform PCA
    const centeredMatrix = PCA.computeDeviationMatrix(matrix.to2DArray());
    const deviationScores = PCA.computeDeviationScores(centeredMatrix);
    const eigenVectors = PCA.getEigenVectors(deviationScores);

    // The normal vector to the plane is the third principal component
    const normal = eigenVectors[2].vector;

    // The point on the plane can be the centroid of the points
    const centroid = new Vector3(
        matrix.mean('column')[0],
        matrix.mean('column')[1],
        matrix.mean('column')[2],
    );

    const vector3Normal = new Vector3(normal[0], normal[1], normal[2]);
    const point = new Vector3(centroid.x, centroid.y, centroid.z);

    return { normal: vector3Normal, point };
};

const projectPointOntoPlane = (point: Vector3, planeNormal: Vector3, planePoint: Vector3): Vector3 => {
    const pointToPlane = new Vector3().subVectors(point, planePoint);
    const distance = pointToPlane.dot(planeNormal);
    const projectedPoint = new Vector3().subVectors(point, planeNormal.clone().multiplyScalar(distance));
    return projectedPoint;
};

const convertTo2D = (points: Vector3[],) => {

    const { normal, point } = findBestPlane(points);

    // Create two orthogonal vectors on the plane
    const u = new Vector3().crossVectors(normal, new Vector3(0, 0, 1)).normalize();
    const v = new Vector3().crossVectors(normal, u).normalize();


    const convertedPoints = points.map(currentPoint => {
        return projectPointOntoPlane(currentPoint, normal, point);
    });

    // Project points onto the plane and convert to 2D
    const twoDimPoints = points.map(currentPoint => {
        const projectedPoint = projectPointOntoPlane(currentPoint, normal, point);
        return new Vector2(projectedPoint.dot(u), projectedPoint.dot(v));
    });

    return {
        twoDimPoints,
        convertedPoints,
        normal,
        point,
    }
};

export const shoeStringArea = (points: Vector3[]) => {

    if (points.length < 3) {
        return {
            area: -1,
            convertedPoints: [],
            normal: new Vector3(),
            point: new Vector3(),
        }
    }

    const { twoDimPoints, normal, point, convertedPoints } = convertTo2D(points);

    const selfIntersecting = willBeSelfIntersecting(twoDimPoints);

    let area = 0;

    for (let i = 0; i < twoDimPoints.length; i++) {
        const j = (i + 1) % twoDimPoints.length;
        area += twoDimPoints[i].x * twoDimPoints[j].y;
        area -= twoDimPoints[j].x * twoDimPoints[i].y;
    }

    area = Math.abs(area) / 2;

    return {
        twoDimPoints,
        area,
        convertedPoints,
        normal,
        point,
        selfIntersecting,
    }
}