import { AccountCircle, ConnectWithoutContact } from "@mui/icons-material";

import ControlButton from "../control-button";
import { useContext } from "react";
import { MeetingInfoContext } from "../../context/meeting-info-context";

interface ShowSelfProps {
    showText?: boolean;
    disabled?: boolean;
}

const LabelledShowSelfButton: React.FC<ShowSelfProps> = ({
    showText,
    disabled = false
}) => {

    const { showSelf, setShowSelf } = useContext(MeetingInfoContext);

    const toggleShowSelf = () => {
        setShowSelf(!showSelf);
    }

    return (
        <ControlButton
            activeIcon={<AccountCircle color="action" />}
            inactiveIcon={<ConnectWithoutContact color="disabled" />}
            active={showSelf}
            onClick={toggleShowSelf}
            disabled={disabled}
            activeLabel={"Hide Self"}
            inactiveLabel={"Show Self"}
            showText={showText} />
    );
}

export default LabelledShowSelfButton;