import React, { createContext, useState } from 'react';
import { MeetingJoinType } from '../types';

interface Props {
    children: React.ReactNode;
}

export type MeetingInfoContextProps = {
    meetingId: string;
    userId: string;
    patientId: string;
    setPatientId: React.Dispatch<React.SetStateAction<string>>;
    userName: string;
    setUserName: React.Dispatch<React.SetStateAction<string>>;
    jobTitle: string;
    setJobTitle: React.Dispatch<React.SetStateAction<string>>;
    meetingType: MeetingJoinType;
    errorMessage: string | null;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
    connected: boolean;
    setConnected: React.Dispatch<React.SetStateAction<boolean>>;
    showSelf: boolean;
    setShowSelf: React.Dispatch<React.SetStateAction<boolean>>;
    otherAttendees: string[];
    setOtherAttendees: React.Dispatch<React.SetStateAction<string[]>>;
    selectedAttendees: string[];
    setSelectedAttendees: React.Dispatch<React.SetStateAction<string[]>>;
    showContentShare: boolean;
    setShowContentShare: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MeetingInfoContext = createContext<MeetingInfoContextProps>({
    meetingId: '',
    userId: '',
    patientId: '',
    setPatientId: () => { },
    userName: '',
    setUserName: () => { },
    jobTitle: '',
    setJobTitle: () => { },
    meetingType: MeetingJoinType.Metro,
    errorMessage: null,
    setErrorMessage: () => { },
    connected: false,
    setConnected: () => { },
    showSelf: false,
    setShowSelf: () => { },
    otherAttendees: [],
    setOtherAttendees: () => { },
    selectedAttendees: [],
    setSelectedAttendees: () => { },
    showContentShare: false,
    setShowContentShare: () => { }
});

export const MeetingInfoProvider: React.FC<Props> = ({ children }) => {
    const [meetingId, setMeetingId] = useState('');
    const [userName, setUserName] = useState('');
    const [patientId, setPatientId] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [userId, setUserId] = useState('');
    const [meetingType, setMeetingType] = useState<MeetingJoinType>(MeetingJoinType.Metro);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showSelf, setShowSelf] = useState(false);
    const [connected, setConnected] = useState(false);
    const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
    const [showContentShare, setShowContentShare] = useState(false);
    const [otherAttendees, setOtherAttendees] = useState<string[]>([]);

    return (
        <MeetingInfoContext.Provider
            value={{
                meetingId,
                userId,
                patientId,
                setPatientId,
                userName,
                setUserName,
                jobTitle,
                setJobTitle,
                meetingType,
                errorMessage,
                setErrorMessage,
                connected,
                setConnected,
                showSelf,
                setShowSelf,
                selectedAttendees,
                otherAttendees,
                setOtherAttendees,
                setSelectedAttendees,
                showContentShare,
                setShowContentShare
            }}
        >
            {children}
        </MeetingInfoContext.Provider>
    );
};